<template>
  <b-container>
    <b-row>
      <b-col cols="1" />
      <b-col>
        <h2>Bike fotografieren</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1" />
      <b-col cols="10">
        <p>Dein aufgenommenes Bild</p>
      </b-col>
      <b-col cols="1" />
    </b-row>
    <b-row>
      <b-col cols="1" />
      <b-col cols="10">
        <!-- shows the captured image -->
        <img :src="img" style="width: 100%; height: 100%" class="img-responsive webCamImg"/>
      </b-col>
      <b-col cols="1" />
    </b-row>
    <b-row>
      <b-col cols="1" />
      <b-col cols="10">
        <b-button class="buttonSpacing" variant="secondary" @click="$router.push('/camera')">Neues Bild aufnehmen</b-button>
        <b-button class="buttonSpacing" variant="primary">Bild verwenden</b-button>
      </b-col>
      <b-col cols="1" />
    </b-row>
  </b-container>
</template>

<script>
  export default {
    name: 'CameraCapture',
    data() {
      return {
        img: null,
      };
    },
    mounted() {
      // loads / shows recieved image on page load
      if (Object.keys(this.$route.params).length > 0) {
        const { params } = this.$route;
        console.log(params);
        this.img = params.img;
      }
    }
  };
</script>